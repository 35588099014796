import axios from "axios";
import { API_ROOT } from "../constants";
const ROOT_URL = API_ROOT;

export function getNotification(params) {
  return axios({
    method: "GET",
    params: params,
    url: `${ROOT_URL}/user/getUserNotifications`,
  });
}
export function Marknotificationasread(params) {
  return axios({
    method: "POST",
    data: params,
    url: `${ROOT_URL}/user/markNotificationAsRead`,
  });
}

export function MarkThreadRead(params) {
  return axios({
    method: "POST",
    data: params,
    url: `${ROOT_URL}/chat/markThreadAsRead`,
  });
}
