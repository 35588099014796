import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
  getNotification,
  Marknotificationasread,
} from "../actions/notifications";
import {
  getAllUnThreadCount,
  getAllUnreadMessageCount,
  getAllchatNotifications,
  NotificationsChatList,
  markAllAsReadchatNotification,
  clearChatNotificcation,
  sortThreadList,
  threadList,
  getThreadList,
  resetcountchatmemberChat,
} from "../actions/chat";
import { API_ROOT } from "../constants";
import NotificationList from "./header/NotificationList";
import ChatNotificationList from "./header/ChatNotificationList";
import { LOGOUT_USER_CLEAR } from "../actions/chat";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";
import "./chat/Chat.css";
import _ from "lodash";
import ChatNotificationMessageReaction from "./chat/ChatNotifcationMessageReaction";
import { getUserDetails } from "../actions/getUserDetail";
import { Dropdown, Tab, Tabs } from "react-bootstrap";

class Header extends Component {
  constructor(props, context) {
    super(props, context);
    this.notificatonref = React.createRef();
    this.chatnotificatonref = React.createRef();
    this.state = {
      chatModal: false,
      notificationModal: false,
      menuDisplay: props.menuDisplay,
      userName: "",
      userImage: null,
      notificationList: [],
      unreadNotificationList: [],
      notificationCount: props.notificationCount,
      activePage: 1,
      userId: "",
      notificationActivePage: 1,
    };
    this.notificationonScroll = this.notificationonScroll.bind(this);
    this.notificationonchatScroll = this.notificationonchatScroll.bind(this);
    this.markAllAsReadchatNotifications =
      this.markAllAsReadchatNotifications.bind(this);
  }
  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("auth"));
    if (user && user.UserProfile && user.UserProfile.name) {
      this.setState({ userName: user.UserProfile.name });
      this.state.userName = user.UserProfile.name;
    }
    // if (user && user.UserProfile && user.UserProfile.Attachment && user.UserProfile.Attachment.path) {
    //    this.setState({ userImage: `${API_ROOT}/${user.UserProfile.Attachment.path}` });
    //    this.state.userImage = `${API_ROOT}/${user.UserProfile.Attachment.path}`;
    // }
    if (user && user.UserProfile && user.UserProfile.user_id) {
      this.setState({ userId: user.UserProfile.user_id });
      this.state.userId = user.UserProfile.user_id;
      this.getUserDetails(this.state.userId);
    }
    // this.getNotifications();
    // this.getAllchatNotification();
  }

  getUserDetails(user_id) {
    return getUserDetails({ userId: user_id })
      .then((result) => {
        let userDetails = result.data.responseData;

        if (
          result.data.responseData &&
          result.data.responseData.UserProfile &&
          result.data.responseData.UserProfile.Attachment &&
          result.data.responseData.UserProfile.Attachment.path
        ) {
          this.setState({
            userImage: `${API_ROOT}/${result.data.responseData.UserProfile.Attachment.path}`,
          });
          // this.setState({ userDetails: result.data.responseData });
        }
      })
      .catch((error) => {});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if (prevState.notificationActivePage != this.state.notificationActivePage) {
    //    this.getNotifications();
    // }
    // else
    // if (prevState.activePage != this.state.activePage) {
    //    this.getAllchatNotification();
    // }
  }
  // getNotifications() {
  //    return getNotification({ pageNumber: this.state.notificationActivePage }).then((result) => {
  //       let notificationArrray = [];
  //       result.data.responseData.data.forEach(notificationcount => {
  //          if (notificationcount?.readStatus === 0) {
  //             notificationArrray.push(notificationcount)
  //          }
  //       });
  //       this.setState({
  //          notificationList: [...this.state.notificationList, ...result.data.responseData.data],
  //       });
  //    }).catch(error => {
  //    })
  // }

  // getAllchatNotification() {
  //    getAllUnThreadCount().then((res) => {
  //       if (res?.data?.responseData != null) {
  //          this.props.getAllUnreadMessageCount(res?.data?.responseData)
  //       }

  //    }).catch(err => {
  //    })
  //    getAllchatNotifications({  pageNumber: this.state.activePage }).then((res) => {
  //       if(this.state.activePage ==1&&res?.data?.responseData?.data.length==0){
  //          this.props.clearChatNotificcation(res?.data?.responseData?.data)
  //       }
  //       else{
  //       this.props.NotificationsChatList(res?.data?.responseData?.data)
  //       }

  //    }).catch(err => {

  //    })
  // }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.notificationCount != nextProps.notificationCount) {
      return { notificationCount: nextProps.notificationCount };
    }
    if (nextProps.menuDisplay !== prevState.menuDisplay) {
      var element = document.getElementsByTagName("body")[0];
      element.classList.toggle("overflow");
      return { menuDisplay: nextProps.menuDisplay };
    } else {
      return null;
    }
  }

  toggleMenu() {
    var element = document.getElementById("sidenav");
    element.classList.toggle("show");

    var element = document.getElementById("hamburger-icon");
    element.classList.toggle("open-nav");

    var element = document.getElementById("sidenav");
    element.classList.toggle("show");

    var element = document.getElementById("wrapper");
    element.classList.toggle("menu-name-close");
  }

  toggleSearch() {
    var element = document.getElementById("search");
    element.classList.toggle("show");
  }

  toggleCloseBtn() {
    var element = document.getElementById("search");
    element.classList.toggle("show");
  }

  addBodyClass() {
    var element = document.getElementsByTagName("body")[0];
    element.classList.toggle("overflow");
  }

  profile() {
    this.props.history.push("/profile");
  }
  marknotificationasread(id) {
    // Marknotificationasread({ notificationId: id }).then((res) => {
    //    this.getNotifications();
    // }).catch(err => console.log(err, 'err'))
  }
  redirection(obj) {
    switch (obj.type) {
      case 1:
        this.props.history.push({
          pathname: "/dashboard/manage-bookings",
          state: obj,
        });
        break;
      case 2:
        this.props.history.push({
          pathname: "/dashboard/tickets",
          state: obj,
        });
        break;
      case 3:
        this.props.history.push({
          pathname: "/dashboard/poll",
          state: obj,
        });
        break;
      case 4:
        this.props.history.push({
          pathname: "/dashboard/event",
          state: obj,
        });
        break;
      case 5:
        this.props.history.push({
          pathname: "/dashboard/blog-news",
          state: obj,
        });
        break;
      default:
        break;
    }
  }
  redirectTochatThreadDetail(obj) {
    var encodeUrl = encodeURIComponent(
      `thread_id=${obj?.ChatMessage?.thread_id}`
    );
    const loadershow = false;
    this.props.history.push({
      pathname: `/dashboard/chat`,
      search: `?buildingId=${
        obj?.building_id
      }&${encodeUrl}&show=${true}&time=${Date.now()}`,
    });
    let findIndexOfthread = _.findIndex(this.props.threadList, [
      "thread_id",
      obj?.ChatMessage?.thread_id,
    ]);
    if (findIndexOfthread > -1) {
      this.props.sortThreadList({
        thread_id: obj?.ChatMessage?.thread_id,
        payload: obj?.ChatMessage,
      });
    }
    if (obj?.building_id) {
      getThreadList({ building_id: obj?.building_id })
        .then((response) => {
          this.props.threadList(response?.data?.responseData?.rows);
          if (obj?.ChatMessage?.thread_id) {
            resetcountchatmemberChat({ thread_id: obj?.ChatMessage?.thread_id })
              .then((res) => {})
              .catch((err) => {
                console.log("err", err);
              });
          }
        })
        .catch((err) => {});
    }
  }

  notificationonScroll(e) {
    if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) {
      this.setState({
        notificationActivePage: this.state.notificationActivePage + 1,
      });
    }
  }
  notificationonchatScroll(e) {
    if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) {
      this.setState({
        activePage: this.state.activePage + 1,
      });
    }
  }
  markAllAsReadchatNotifications(e) {
    markAllAsReadchatNotification({
      buildingId: localStorage.getItem("buildingId"),
    })
      .then((res) => {
        this.props.getAllUnreadMessageCount(0);
      })
      .catch((err) => {});
  }
  stripHtmlTags(str, length) {
    if (str === null || str === "") {
      return false;
    } else {
      str = str?.toString();
      str = str?.replace(/<[^>]*>/g, "");
      if (length && length > 0 && str?.length > length) {
        str = str?.substr(0, length);
        str = str + "...";
      }
    }
    return str;
  }

  render() {
    const { userImage, notificationList, notificationCount } = this.state;

    return (
      <div>
        <header id="header-panel">
          <div className="row">
            <div className="col-sm-6 col-4">
              <button
                id="hamburger-icon"
                className={`${"hamburger-icon"} ${
                  this.state.menuDisplay ? "open-nav" : ""
                }`}
                onClick={() =>
                  this.props.menuToBeDisplayedCallback(
                    this.state.menuDisplay ? false : true
                  )
                }
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              {/* search-bar comment on 08-05-2024 */}
              {/* <div id="search" className="search">
                        <button onClick={() => this.toggleSearch()} className="search-btn"></button>
                        <input className="search-input" type="text" placeholder="Search for building" />
                        <button className="close-btn" onClick={() => this.toggleCloseBtn()}></button>
                     </div> */}
            </div>
            <div className="col-sm-6 col-8">
              <div className="header-right">
                <ul className="bell">
                  <NotificationList />
                  <ChatNotificationList />

                  <li>
                    <div className="dropdown profile">
                      <button
                        type="button"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <span className="profile-pic">
                          <img
                            className="img-fluid"
                            src={
                              userImage
                                ? userImage
                                : "/assets/images/no-user.jpeg"
                            }
                          />
                        </span>
                        <span className="name">{this.state.userName}</span>
                      </button>
                      <div className="dropdown-menu">
                        <ul>
                          <NavLink
                            to="/profile"
                            activeClassName="active"
                            className="dropdown-item"
                          >
                            Profile
                          </NavLink>
                          <NavLink
                            to="/"
                            className="dropdown-item"
                            onClick={this.props.logOut}
                          >
                            Log Out
                          </NavLink>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => {
      dispatch(LOGOUT_USER_CLEAR());
    },
    getAllUnreadMessageCount: (payload) => {
      dispatch(getAllUnreadMessageCount(payload));
    },
    NotificationsChatList: (payload) => {
      dispatch(NotificationsChatList(payload));
    },
    clearChatNotificcation: (payload) => {
      dispatch(clearChatNotificcation(payload));
    },
    sortThreadList: (thread_id, payload) => {
      dispatch(sortThreadList(thread_id, payload));
    },
    threadList: (payload) => {
      dispatch(threadList(payload));
    },
  };
};
const mapStatesToProps = (state, ownProps) => {
  return {
    chatNotificationCount: state.Chats.chatNotificationCount,
    notificationChatList: state.Chats.notificationChatList,
    threadList: state.Chats.threadList,
  };
};
export default withRouter(
  connect(mapStatesToProps, mapDispatchToProps)(Header)
);
