import axios from "axios";
import { data } from "jquery";
import { API_ROOT, _dispatch } from "../constants";
import { Redirect } from "react-router-dom";
import { socket } from "../components/Dashboard";
export { _dispatch };
export const CHAT_MEMBER_SEARCH = "CHAT_MEMBER_SEARCH";
export const SINGLE_MEMBER_SEARCH_LIST = "SINGLE_MEMBER_SEARCH_LIST";
export const SET_CHAT_MESSAGE = "SET_CHAT_MESSAGE";
export const APPEND_CHAT_MESSAGE = "APPEND_CHAT_MESSAGE";
export const GET_THREAD_LIST = "GET_THREAD_LIST";
export const SINGLE_THREAD_LIST = "SINGLE_THREAD_LIST";
export const UPDATE_CHAT_MESSAGE = "UPDATE_CHAT_MESSAGE";
export const REPLY_CHAT_MESSAGE = "REPLY_CHAT_MESSAGE";
export const ONLINE_CHAT_USER = "ONLINE_CHAT_USER";
export const MEMBER_ALREADY_GROUP = "MEMBER_ALREADY_GROUP";
export const UPDATE_MESSAGE_COUNT_STATUS = "UPDATE_MESSAGE_COUNT_STATUS";
export const DECREASE_MESSAGE_COUNT = "DECREASE_MESSAGE_COUNT";
export const INCREASE_MESSAGE_COUNT = "INCREASE_MESSAGE_COUNT";
export const GET_ALL_UNREAD_MESSAGE_COUNT = "GET_ALL_UNREAD_MESSAGE_COUNT";
export const PREPEND_CHAT_MESSAGE = "PREPEND_CHAT_MESSAGE";
export const GET_ALL_NOTIFICATION_CHAT = "GET_ALL_NOTIFICATION_CHAT";

export const APPEND_NOTIFICATION_CHAT = "APPEND_NOTIFICATION_CHAT";
export const SORT_THREADLIST_CHAT = "SORT_THREADLIST_CHAT";
export const CLEAR_CHAT_NOTFICATION = "CLEAR_CHAT_NOTFICATION";
export const FORWARD_MESSAGE_LIST = "FORWARD_MESSAGE_LIST";
const ROOT_URL = API_ROOT;
export function getchatMemberSearch(payload) {
  return {
    type: "CHAT_MEMBER_SEARCH",
    payload: payload,
  };
}

export function prependChatMessages(payload) {
  return {
    type: PREPEND_CHAT_MESSAGE,
    payload: payload,
  };
}
export function getsingleMemberList(payload) {
  return {
    type: SINGLE_MEMBER_SEARCH_LIST,
    payload: payload,
  };
}
export function MemberAlreadyInGroup(payload) {
  return {
    type: MEMBER_ALREADY_GROUP,
    payload: payload,
  };
}
export const getMessagesList = (params) => {
  return axios({
    method: "GET",
    url: `${API_ROOT}/chat/messages`,
    params,
  });
};

export const singlethreadChatMember = (params) => {
  return axios({
    method: "GET",
    url: `${API_ROOT}/chat/members`,
    params,
  });
};
export function singleThreadMemberChat(payload) {
  return {
    type: SINGLE_THREAD_LIST,
    payload: payload,
  };
}
export const getThreadList = (params) => {
  return axios({
    method: "GET",
    url: `${API_ROOT}/chat/threads`,
    params,
  });
};
export function updateMessage(payload) {
  console.log("payload", payload);
  return new Promise((resolve, reject) => {
    socket.emit("updateMessage", payload, null, (message) => {
      if (message?.error) {
        reject(message.error);
      } else {
        resolve(message);
      }
    });
  });
}
export function postMessage(payload) {
  console.log("res?.message?.isValid", payload);
  return new Promise((resolve, reject) => {
    socket.emit("newMessage", payload, null, (message) => {
      if (message?.error) {
        reject(message.error);
      } else {
        resolve(message);
      }
    });
  });
}
export function setChatMessages(payload) {
  return {
    type: "SET_CHAT_MESSAGE",
    payload: payload,
  };
}
export function appendChatMessage(payload) {
  return {
    type: "APPEND_CHAT_MESSAGE",
    payload: payload,
  };
}
export function replyChatMessage(payload) {
  return {
    type: REPLY_CHAT_MESSAGE,
    payload: payload,
  };
}
export function updateChatMessage(payload) {
  return {
    type: UPDATE_CHAT_MESSAGE,
    payload: payload,
  };
}
export function threadList(payload) {
  return {
    type: "GET_THREAD_LIST",
    payload: payload,
  };
}
export const createThread = (payload) => {
  return axios({
    method: "POST",
    url: `${API_ROOT}/chat/createThread`,
    data: payload,
  });
};
export const editGroup = (payload) => {
  return axios({
    method: "PATCH",
    url: `${API_ROOT}/chat/updateGroup`,
    data: payload,
  });
};
export const addToExistingMember = (payload) => {
  return axios({
    method: "POST",
    url: `${API_ROOT}/chat/member`,
    data: payload,
  });
};
export function leaveGroup(payload) {
  return new Promise((resolve, reject) => {
    socket.emit("leaveGroup", payload, null, (message) => {
      console.log("leavemessage", message);
      if (message?.error) {
        reject(message.error);
      } else {
        resolve(message);
      }
    });
  });
}
export const forwardMessages = (payload) => {
  console.log("response12", payload);
  return new Promise((resolve, reject) => {
    socket.emit("forwardMessage", payload, null, (message) => {
      if (message?.error) {
        reject(message.error);
      } else {
        resolve(message);
      }
    });
  });
};
export const removeMessage = (payload) => {
  return new Promise((resolve, reject) => {
    socket.emit("deleteMessage", payload, null, (message) => {
      if (message?.error) {
        reject(message.error);
      } else {
        resolve(message);
      }
    });
  });
};
export const replyMessage = (payload) => {
  return new Promise((resolve, reject) => {
    socket.emit("reply", payload, null, (message) => {
      if (message?.error) {
        reject(message.error);
      } else {
        resolve(message);
      }
    });
  });
};
export const messageReaction = (payload) => {
  return new Promise((resolve, reject) => {
    socket.emit("reaction", payload, null, (message) => {
      if (message?.error) {
        reject(message.error);
      } else {
        resolve(message);
      }
    });
  });
};
export function onlineMember(payload) {
  return {
    type: ONLINE_CHAT_USER,
    payload: payload,
  };
}
export function kickUsers(payload) {
  return new Promise((resolve, reject) => {
    socket.emit("kickUser", payload, null, (message) => {
      if (message?.error) {
        reject(message.error);
      } else {
        resolve(message);
      }
    });
  });
}
export const getChatGetattachments = (params) => {
  return axios({
    method: "GET",
    url: `${API_ROOT}/chat/getAttachments`,
    params,
  });
};
export const readMessageCount = (payload) => {
  return new Promise((resolve, reject) => {
    socket.emit("resetCount", payload, null, (message) => {
      if (message?.error) {
        reject(message.error);
      } else {
        resolve(message);
      }
    });
  });
};
export function updateMessageCount(payload) {
  return {
    type: UPDATE_MESSAGE_COUNT_STATUS,
    payload: payload,
  };
}
export function decreaseMessageCount(payload) {
  return {
    type: DECREASE_MESSAGE_COUNT,
    payload: payload,
  };
}
export function increaseMessageCount(payload) {
  return {
    type: INCREASE_MESSAGE_COUNT,
    payload: payload,
  };
}
export const getAllUnThreadCount = (params) => {
  return axios({
    method: "GET",
    url: `${API_ROOT}/chat/getAllUnreadCounts`,
    params,
  });
};
export function getAllUnreadMessageCount(payload) {
  return {
    type: GET_ALL_UNREAD_MESSAGE_COUNT,
    payload: payload,
  };
}
export function getAllchatNotifications(params) {
  return axios({
    method: "GET",
    url: `${API_ROOT}/chat/getChatNotifications`,
    params,
  });
}

export function NotificationsChatList(payload) {
  return {
    type: GET_ALL_NOTIFICATION_CHAT,
    payload: payload,
  };
}
export function appendNotificationChatMessages(payload) {
  return {
    type: APPEND_NOTIFICATION_CHAT,
    payload: payload,
  };
}
export function sortThreadList(payload) {
  return {
    type: SORT_THREADLIST_CHAT,
    payload: payload,
  };
}
export const markAllAsReadchatNotification = (payload) => {
  return axios({
    method: "POST",
    url: `${API_ROOT}/chat/markAllAsRead`,
    data: payload,
  });
};
export function clearChatNotificcation(payload) {
  return {
    type: CLEAR_CHAT_NOTFICATION,
    payload: payload,
  };
}
export function LOGOUT_USER_CLEAR() {
  if (socket) {
    socket.disconnect();
  }
  localStorage.clear();
  <Redirect to="/" />;
  return {
    type: "LOGOUT_USER_CLEAR",
  };
}
export const resetcountchatmemberChat = (payload) => {
  return axios({
    method: "POST",
    url: `${API_ROOT}/chat/resetCountChatMember`,
    data: payload,
  });
};
export function prependForwardChatMessages(payload) {
  return {
    type: FORWARD_MESSAGE_LIST,
    payload: payload,
  };
}
