import { load } from './utilities';
import React from 'react';
import { Redirect } from 'react-router-dom';

export const ROUTES = {
  /*ROOT: {
    path: '/',
    exact: true,
    render: () => <Redirect to="landing" />
  },*/
  HOME: {
    path: '/',
    exact: true,
    render: () => <Redirect to="/login" />
  },
  LANDING: {
    path: '/landing',
    exact: true,
    component: load('landing/Landing')
  },
  ROOT: {
    path: '/',
    exact: true,
    component: load('home/home')
  },
  FORGOTPASSWORD: {
    path: '/forgot-password',
    exact: true,
    component: load('forgotPassword/forgotPassord')
  },
  RESETPASSWORD: {
    path: '/reset-password',
    exact: true,
    component: load('resetPassword/resetPassword')
  },
  DASHBOARD: {
    path: '/dashboard',
    component: load('Dashboard')
  },
  LOGIN: {
    path: '/login',
    exact: true,
    component: load('login/Login')
  },
  OVERVIEW: {
    path: '/dashboard/overview',
    exact: true,
    component: load('overview/Overview'),
  },
  ALARM: {
    path: '/dashboard/alarm',
    exact: true,
    component: load('alarm/Alarm')
  },
  ALARMSETTINGS: {
    path: '/dashboard/alarm/settings',
    exact: true,
    component: load('alarmSettings/AlarmSettings')
  },
  BLOGNEWS: {
    path: '/dashboard/blog-news',
    exact: true,
    component: load('news/News')
  },
  BLOG: {
    path: '/blogs',
    exact: true,
    component: load('all-blogs/allBlogs')
  },
  HOMEBLOGDETAIL: {
    path: '/blogs/:slug',
    exact: true,
    component: load('homeBlogDetail/homeBlogDetail')
  },
  BLOGDETAILMOBILE: {
    path: '/blog/detail/:slug/:token',
    exact: true,
    component: load('blog-detail-mobile/BlogDetails')
  },
  BLOGDETAILS: {
    path: '/dashboard/blog-news/details/:slug',
    exact: true,
    component: load('blog-details/BlogDetails')
  },
  CREATEBLOG: {
    path: '/blog-news/create-blog',
    exact: true,
    component: load('createBlog/CreateBlog')
  },
  CREATEPOLL: {
    path: '/poll/create',
    exact: true,
    component: load('createPoll/createPoll')
  },
  EDITPOLL: {
    path: '/poll/edit/:pollId',
    exact: true,
    component: load('createPoll/createPoll')
  },
  CREATEEVENT: {
    path: '/event/create',
    exact: true,
    component: load('createEvent/createEvent')
  },
  EDITEVENT: {
    path: '/event/edit/:eventId',
    exact: true,
    component: load('createEvent/createEvent')
  },
  EVENTDETAILMOBILE: {
    path: '/event/detail/:slug/:token',
    exact: true,
    component: load('event-detail-mobile/EventDetails')
  },
  EVENTDETAILS: {
    path: '/dashboard/event/:id',
    exact: true,
    component: load('event-details/EventDetail')
  },
  EDITBLOG: {
    path: '/blog-news/edit-blog/:blogId',
    exact: true,
    component: load('createBlog/CreateBlog')
  },
  BUILDINGSCHEDULE: {
    path: '/dashboard/building-schedule',
    exact: true,
    component: load('buildingSchedule/buildingSchedule')
  },
  BUILDINGUSERS: {
    path: '/dashboard/building-users',
    exact: true,
    component: load('buildingUsers/BuildingUsers')
  },
  BUILDINGUSERSVISITORS: {
    path: '/dashboard/building-users/:name/:id',
    exact: true,
    component: load('buildingUsers/visitorsVisit/visitorsVisit')
  },
  BUILDINGTENANTS: {
    path: '/dashboard/building-tenants',
    exact: true,
    component: load('tenants/tenants')
  },
  TENANTHANDBOOK: {
    path: '/dashboard/tenant-handbook',
    exact: true,
    component: load('tenant-handbook/tenantHandBookList')
  },
  TENANTHANDBOOKDETAIL: {
    path: '/dashboard/tenant-handbook/:id',
    exact: true,
    component: load('tenant-handbook/tenantHandBookDetail')
  },
  TENANTHANDBOOKDETAILMOBILE: {
    path: '/tenant-handbook/detail/:id/:token',
    exact: true,
    component: load('tenant-handbook-detail-mobile/tenantHandbookDetail')
  },
  PERKS: {
    path: '/dashboard/perks',
    exact: true,
    component: load('perks/perks')
  },
  PERKDETAILMOBILE: {
    path: '/perk/detail/:id/:token',
    exact: true,
    component: load('perk-detail-mobile/perk-detail-mobile')
  },
  SHOPS: {
    path: '/dashboard/shops',
    exact: true,
    component: load('shops/shops')
  },
  SHOPSDETAILMOBILE: {
    path: '/shop/detail/:id/:token',
    exact: true,
    component: load('shop-detail-mobile/shopDetail')
  },
  PERKSDETAIL: {
    path: '/dashboard/perks/:id',
    exact: true,
    component: load('perks/perksDetail')
  },
  BOOKINGS: {
    path: '/dashboard/bookings',
    exact: true,
    component: load('bookings/Bookings')
  },
  REPORTS: {
    path: '/dashboard/reports',
    exact: true,
    component: load('reports/Reports')
  },
  // PARCELS
  PARCELS: {
    path: '/dashboard/parcels',
    exact: true,
    component: load('parcels/Parcels')
  },

  TICKETS: {
    path: '/dashboard/tickets',
    exact: true,
    component: load('tickets/tickets')
  },
  TICKETSSETTINGS: {
    path: '/dashboard/tickets/settings',
    exact: true,
    component: load('ticketSettings/TicketSettings')
  },
  INFORMATION: {
    path: '/dashboard/information',
    exact: true,
    component: load('information/information')
  },
  DOCUMENTS:{
    path: '/dashboard/documents',
    exact: true,
    component: load('documents/documents')
  },
  ANALYTICS: {
    path: '/dashboard/analytics',
    exact: true,
    component: load('analytics/analytics')
  },
  ELECTRICAL: {
    path: '/dashboard/building-system/electrical/summary',
    exact: true,
    // component: load('buildingSystem/electrical/summary/summary')
    component: load('buildingSystem/electrical/summary/summary-new')

  },
  IAQ: {
    path: '/dashboard/building-system/iaq',
    exact: true,
    component: load('buildingSystem/iaq/iaq')
  },
  IAQNEW: {
    path: '/dashboard/building-system/iaq-new',
    exact: true,
    component: load('buildingSystem/iaq-new/iaq')
  },
  GENERATOR: {
    path: '/dashboard/building-system/electrical/generator',
    exact: true,
    component: load('buildingSystem/electrical/generator/generator')
  },

  WATER: {
    path: '/dashboard/building-system/electrical/water',
    exact: true,
    component: load('buildingSystem/electrical/water/water')
  },
  OCCUPANY:{
    path:'/dashboard/building-system/occupany',
    exact: true,
    component: load('buildingSystem/occupancy/occupancy')

  },
  METERING: {
    path: '/dashboard/building-system/metering',
    exact: true,
    component: load('buildingSystem/electrical/metering/metering')
  },
  SOLARPV: {
    path: '/dashboard/building-system/solarPV',
    exact: true,
    component: load('buildingSystem/solarPv/solarPV')
  },
  ASSETREGISTER: {
    path: '/dashboard/building-system/operations/assetRegister',
    exact: true,
    component: load('buildingSystem/operations/assetRegister/assetRegister')
  },
  ASSETREGISTERDETAIL: {
    path: '/dashboard/building-system/operations/assetRegister/:slug',
    exact: true,
    component: load('buildingSystem/operations/assetRegister/assetRegisterDetail')
  },
  EDITASSETREGISTERDETAIL: {
    path: '/dashboard/building-system/operations/assetRegister/edit/:slug',
    exact: true,
    component: load('buildingSystem/operations/assetRegister/editAssetRegisterDetail')
  },
  DIGITALDISPLAY: {
    path: '/dashboard/building-system/operations/digital-display',
    exact: true,
    component: load('buildingSystem/operations/digitalDisplay/DigitalDisplay')
  },
  MECHANICAL: {
    path: '/dashboard/building-system/mechanical/summary',
    exact: true,
    component: load('buildingSystem/mechanical/summary/summary')
  },
  VAV: {
    path: '/dashboard/building-system/mechanical/vav',
    exact: true,
    component: load('buildingSystem/mechanical/vav/VAV')
  },
  POLL: {
    path: '/dashboard/poll',
    exact: true,
    component: load('poll/poll')
  },
  EVENT: {
    path: '/dashboard/event',
    exact: true,
    component: load('event/event')
  },
  LIFT1: {
    path: '/dashboard/building-system/lifts/1',
    exact: true,
    component: load('buildingSystem/lifts/lift-1/lift-1')
  },
  FLOORPLAN: {
    path: '/dashboard/floor-plan',
    exact: true,
    component: load('floor-plan/FloorPlan')
  },
  MANAGEASSET: {
    path: '/dashboard/manage-asset',
    exact: true,
    component: load('manage-asset/ManageAsset')
  },
  TRANSACTION: {
    path: '/dashboard/transaction-payment',
    exact: true,
    component: load('transaction-payment/Transaction')
  },
  FLOORSETTINGS: {
    path: '/dashboard/floor-settings',
    exact: true,
    component: load('floor-settings/FloorSettings')
  },
  MAINTENANCESCHEDULE: {
    path: '/dashboard/maintenance-schedule',
    exact: true,
    component: load('maintenanceSchedule/maintenanceSchedule')
  },
  LIFTSYSTEM: {
    path: '/dashboard/building-system/lifts',
    exact: true,
    component: load('buildingSystem/lifts/liftSystem/liftSystem')
  },
  CARPARKING: {
    path: '/dashboard/building-system/car-parking',
    exact: true,
    component: load('buildingSystem/carParking/CarParking')
  },
  MANAGEBOOKINGS: {
    path: '/dashboard/manage-bookings',
    exact: true,
    component: load('manage-bookings/ManageBookings')
  },
  MANAGEOTHERS: {
    path: '/dashboard/manage-others',
    exact: true,
    component: load('manage-others/ManageOthers')
  },
  VAVSUMMARY: {
    path: '/dashboard/building-system/mechanical/vav/summary',
    exact: true,
    component: load('buildingSystem/mechanical/vavSummary/VAVSummary')
  },
  RESOURCES: {
    path: '/dashboard/resources',
    exact: true,
    component: load('resources/resources')
  },
  AHU: {
    path: '/dashboard/building-system/mechanical/ahu',
    exact: true,
    component: load('buildingSystem/mechanical/ahu/ahu')
  },
  AHUSUMMARY: {
    path: '/dashboard/building-system/mechanical/ahu/summary',
    exact: true,
    component: load('buildingSystem/mechanical/ahuSummary/ahuSummary')
  },
  NEWSFEED: {
    path: '/dashboard/blog-news/feed',
    exact: true,
    component: load('newsfeed/newsfeed')
  },
  PROFILE: {
    path: '/profile',
    exact: true,
    component: load('profile/Profile')
  },
  EDITPROFILE: {
    path: '/profile/edit',
    exact: true,
    component: load('editProfile/EditProfile')
  },
  CHILLERS: {
    path: '/dashboard/building-system/mechanical/chillers',
    exact: true,
    component: load('buildingSystem/mechanical/chiller/chiller')
  },

  NOTFOUND: {
    component: load('Sidebar')
  },
  PRIVACY_POLICY: {
    path: '/privacy/policy',
    exact: true,
    component: load('pages/PrivacyPolicy')
  },
  TERMS: {
    path: '/terms-conditions',
    exact: true,
    component: load('pages/Terms')
  },
  FAQS: {
    path: '/faq',
    exact: true,
    component: load('pages/Faq')
  },
  WELCOME_SCREEN: {
    path: '/welcome',
    exact: true,
    component: load('pages/WelcomeScreen')
  },
  //chat route
  CHATDASHBOARD: {
    path: '/dashboard/chat',
    exact: true,
    component: load('chat/ChatDashboard')
  },
  SETTINGS: {
    path: '/dashboard/settings',
    exact: true,
    component: load('settings/Settings')
  },
  ACCESS_CARD_PUBLIC_PAGE: {
    path: '/access-card/:token',
    exact: true,
    component: load('pages/AccessCardPage')
  },
  MANAGE_ROLES: {
    path: '/dashboard/roles',
    exact: true,
    component: load('acl/ManageRoles')
  },

  VISITORS_VISIT:{
    path:'/dashboard/events-visitors',
    exact:true,
    component:load('visitorsVisit/visitorsVisit')
  },
  VISITORS_EVENT_VIEW:{
    path:'/dashboard/events-visitors/visitors-view/:id',
    exact:true,
    component:load('visitorsVisit/visitorsVisitView')
  },

  POWER_USAGE_REPORT:{
    path:'/power-usage-report',
    exact:true,
    component:load('pages/powerUsageReport/PowerUsageReport')
  }
}