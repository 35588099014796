import React, { Component, Suspense, createContext, useEffect, useState } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { ROUTES } from './routes';
import ContentLoader from './components/ContentLoader';
import NetworkError from './components/NetworkError';

export const NewContext = createContext();
const App = () => {
  const [cardIndexValue, setCardIndexValue] = useState('');

  // const [isOnline, setIsOnline] = useState(navigator.onLine);  

  useEffect(() => {
    const handleOnline = () => {
      alert('You are now online!');
    };

    const handleOffline = () => {
      alert('You are offline. Please check your internet connection.');
    };

    // Listen for online and offline events
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup the event listeners when component unmounts
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      <NewContext.Provider value={{ cardIndexValue, setCardIndexValue }}>

        <BrowserRouter>
        {/* {isOnline ? ( */}
          <Suspense fallback={<ContentLoader />}>
            <Switch>
              {/* <Route {...ROUTES.ROOT} /> */}
              <Route {...ROUTES.HOME} />
              <Route {...ROUTES.LOGIN} />
              <Route {...ROUTES.FORGOTPASSWORD} />
              <Route {...ROUTES.RESETPASSWORD} />
              <Route {...ROUTES.BLOG} />
              <Route {...ROUTES.PRIVACY_POLICY} />
              <Route {...ROUTES.WELCOME_SCREEN} />
              <Route {...ROUTES.TERMS} />
              <Route {...ROUTES.FAQS} />
              <Route {...ROUTES.HOMEBLOGDETAIL} />
              <Route {...ROUTES.BLOGDETAILMOBILE} />
              <Route {...ROUTES.LANDING} />
              <Route {...ROUTES.DASHBOARD} />
              <Route {...ROUTES.CREATEBLOG} />
              <Route {...ROUTES.CREATEPOLL} />
              <Route {...ROUTES.CREATEEVENT} />
              <Route {...ROUTES.EDITEVENT} />
              <Route {...ROUTES.EVENTDETAILMOBILE} />
              <Route {...ROUTES.TENANTHANDBOOKDETAILMOBILE} />
              <Route {...ROUTES.SHOPSDETAILMOBILE} />
              <Route {...ROUTES.PERKDETAILMOBILE} />
              <Route {...ROUTES.EDITPOLL} />
              <Route {...ROUTES.EDITBLOG} />
              <Route {...ROUTES.PROFILE} />
              <Route {...ROUTES.EDITPROFILE} />
              <Route {...ROUTES.ACCESS_CARD_PUBLIC_PAGE} />
              <Route {...ROUTES.POWER_USAGE_REPORT}/>
              
            </Switch>
          </Suspense>
          
          {/* ): (
            <NetworkError />
          )} */}

        </BrowserRouter>
      </NewContext.Provider>
    </>
  );

}

export default App;
