import axios from 'axios';
import { data } from 'jquery';
import { API_ROOT, _dispatch } from '../constants';
export {_dispatch};
const ROOT_URL = API_ROOT;

export const ROLE_LISTING = 'ROLE_LISTING';
export const DELETE_ROLE = 'DELETE_ROLE';

///permission
export const getPermissions = (props) => {
    return axios({
        method: 'GET',
        params: props,
        url: `${ROOT_URL}/admin/getPermissions`
    });
};

export function getRoles(props){
    return axios({
        method: 'GET',
        params: props,
        url: `${ROOT_URL}/admin/listRoles`
    });
};

export function roleListingSuccess(payload){
  return{
    type: "ROLE_LISTING",
    payload: payload
  }
}

export function getRoleById(id){
    return axios({
        method: 'GET',
        // params: payload,
        url: `${ROOT_URL}/admin/role/${id}`,
    })
}
export function addRole(props){
    return axios({
        method: 'POST',
        data: props,
        url: `${ROOT_URL}/admin/createRole`
    });
};
export function editRole(payload, id){
    console.log('-----', id);
    return axios({
        method: 'PATCH',
        data: payload,
        url: `${ROOT_URL}/admin/updateRole/`+id
    })
}
export function deleteRole(id){
    return axios({
        method: 'DELETE',
        url: `${ROOT_URL}/admin/role/`+id
    })
}

export function deleteRoleSuccess(payload){
  return{
    type: "DELETE_ROLE",
    payload: payload
  }
}

export function updateRoleStatus(payload, id){
    // console.log('-----', id);
    return axios({
        method: 'PATCH',
        data: payload,
        url: `${ROOT_URL}/admin/role/${id}/status`
    })
}



