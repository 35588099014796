import {
    _dispatch,
    ROLE_LISTING,
    DELETE_ROLE
} from '../actions/acl';

const INITIAL_STATE = { status: null, role_list: {} };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ROLE_LISTING:
          return _dispatch({ ...state, role_list: action.payload }, true, 'rolelist');
          break;
        case DELETE_ROLE:
          return (() => {
            const data = [...state.role_list];
            data.splice(action.payload, 1);
            return _dispatch({ ...state, role_list: data}, true, 'rolelist');
          })();
          break;
        default:
            return state;
    }
}