import axios from 'axios';
import { data } from 'jquery';
import { API_ROOT, _dispatch } from '../constants';
export const TICKET_SUMMARY_LIST_SUCCESS = 'TICKET_SUMMARY_LIST_SUCCESS';
const ROOT_URL = API_ROOT;
export {_dispatch};


export function getTicketSummary(data) {
  if(data.dateCode != ''){
    return axios({
      method: 'GET',
      params: data,
      url: `${ROOT_URL}/admin/getTicketSummary`,
    });
  } else {
    return axios({
      method: 'GET',
      url: `${ROOT_URL}/admin/getTicketSummary`,
    });
  }
  
}

export function getTicketSummarySuccess(payload){
  return{
    type: "TICKET_SUMMARY_LIST_SUCCESS",
    payload: payload
  }
}

