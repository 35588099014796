import axios from 'axios';
import { data } from 'jquery';
import { API_ROOT ,_dispatch } from '../constants';
export {_dispatch};
export const TRANSACTION_LIST_SUCCESS = 'TRANSACTION_LIST_SUCCESS';

const ROOT_URL = API_ROOT;

export function getUserPayment(data) {
  return axios({
    method: 'GET',
    params: data,
    url: `${ROOT_URL}/admin/getTransactionList`,
  });
}


export function getTransactionSuccess(payload){
    return{
      type: "TRANSACTION_LIST_SUCCESS",
      payload: payload
    }
  }
  


export function paymentRefund(props){
  return axios({
    method:'POST',
    url:`${ROOT_URL}/admin/refundUserPayment`,
    data:props
  })
}


export function getTransactionDetail(data) {
  return axios({
    method: 'GET',
    params: data,
    url: `${ROOT_URL}/admin/getTransactionById`,
  });
}
