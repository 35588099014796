import { lazy } from 'react';
import { socket } from './components/Dashboard';
import { Redirect } from 'react-router-dom';
export const APP_VERSION = '1.0';

export function load(component){

  return lazy(() => import(`./components/${component}`));
}
export const errorType = [
  "manual",
  "required",
  "pattern",
  "validate",
  "minLength",
  "maxLength",
  "max",
  "min",
  "positive",
  "lessThanTen",
  "greaterThan",
  "checkUrl",
];
export const GALLAHAR_TYPES = [
  { id: 1, name: "Gallaghar" },
  { id: 2, name: "Open Path" },
  { id: 3, name: "Hid" },
];
export function authentication() {
  if (typeof localStorage.getItem('jwtToken') !== 'undefined' && localStorage.getItem('jwtToken') !== null) {return true;}
  return false;
}
export function logOut() {
  if(socket){
  socket.disconnect(); 
  }
  localStorage.clear();
   <Redirect to ="/"/>
  return {
    type: "LOGOUT_USER",
  }  
}
export function clearSession(){
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('currencyCode');
  localStorage.removeItem('currencySymbol');
  localStorage.removeItem('auth');
  localStorage.removeItem('contentlanguage');
  localStorage.removeItem('dieterystatus');
  localStorage.removeItem('permissions');
  localStorage.removeItem('hasAllAccess');
  window.location = '/';
}

export function getAclChecks(permissionType){
  var result = 0;
  let record = JSON.parse(localStorage.getItem('auth'));
  console.log('00000000s000000', record);
  if(record?.hasAllAccess == 'true' || record?.hasAllAccess == 1){
    result = 1;
  } else {
    var userpermissions = record?.Permissions;
    userpermissions && userpermissions.length > 0 && userpermissions.map((obj, index) => (
      permissionType == obj ? result = 1 : null
    ))
  }
  return result;
}
