import {
    _dispatch,
    USERS_LIST_SUCCESS
} from '../actions/getUsers';



const INITIAL_STATE = { status: null, user_list: {} };



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case USERS_LIST_SUCCESS:
            return _dispatch({ ...state, user_list: action.payload }, true, 'usersChkList');
            break;
         case 'LOGOUT_USER':
        default:
            return state;
    }
}