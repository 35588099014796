import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';
export {_dispatch};
export const TICKET_LIST_SUCCESS = 'TICKET_LIST_SUCCESS';
export const BUILDING_ID = 'BUILDING_ID';
const ROOT_URL = API_ROOT;

export function getTicketList(data) {
  
  return axios({
    method: 'GET',
    params: data,
    url: `${ROOT_URL}/admin/listTickets`,
  });
}

export function getTicketListSuccess(payload){
  return{
    type: "TICKET_LIST_SUCCESS",
    payload: payload
  }
}

export function getBuldingSuccess(payload){
  return{
    type: "BUILDING_ID",
    payload: payload
  }
}
