import {
    _dispatch,
    TICKET_SUMMARY_LIST_SUCCESS
    } from '../actions/getTicketSummary';
    
    
    
    const INITIAL_STATE = {status: null, summaryData: {} };
    
    
    
    export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case TICKET_SUMMARY_LIST_SUCCESS:
            return _dispatch({ ...state, summaryData: action.payload}, true, 'ticketSummaryChkList');
            break;
        
        default:
        return state;
    }
    }