import {
  _dispatch,
  CHAT_MEMBER_SEARCH,
  SINGLE_MEMBER_SEARCH_LIST,
  SET_CHAT_MESSAGE,
  GET_THREAD_LIST,
  APPEND_CHAT_MESSAGE,
  UPDATE_CHAT_MESSAGE,
  REPLY_CHAT_MESSAGE,
  ONLINE_CHAT_USER,
  MEMBER_ALREADY_GROUP,
  UPDATE_MESSAGE_COUNT_STATUS,
  DECREASE_MESSAGE_COUNT,
  INCREASE_MESSAGE_COUNT,
  GET_ALL_UNREAD_MESSAGE_COUNT,
  PREPEND_CHAT_MESSAGE,
  GET_ALL_NOTIFICATION_CHAT,
  // GET_ALL_NOTIFICATION,
  APPEND_NOTIFICATION_CHAT,
  SORT_THREADLIST_CHAT,
  CLEAR_CHAT_NOTFICATION,
  FORWARD_MESSAGE_LIST,
} from "../actions/chat";
import _ from "lodash";

const INITIAL_STATE = {
  thread_id: {},
  buildingId: null,
  chatMessageList: [],
  onlineUser: [],
  threadList: [],
  chatNotificationCount: 0,
  notificationChatList: [],
  notificationList: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHAT_MEMBER_SEARCH:
      return _dispatch(
        { ...state, chatmembersearchList: action.payload },
        true,
        "chatmembersearch"
      );
      break;
    case SINGLE_MEMBER_SEARCH_LIST:
      return _dispatch(
        { ...state, singlechatmember: action.payload },
        true,
        "singlechatmember"
      );
      break;
    case SET_CHAT_MESSAGE:
      return _dispatch(
        {
          ...state,
          chatMessageList: action.payload.payload,
        },
        true,
        "chatNessageList"
      );
      break;
    case APPEND_CHAT_MESSAGE:
      let index = 0;
      index = _.findIndex(state.chatMessageList, [
        "id",
        action.payload.payload.id,
      ]);
      if (index > -1) {
        let updateDeleteMessage = [...state.chatMessageList];
        updateDeleteMessage[index] = action.payload.payload;
        return _dispatch(
          {
            ...state,
            chatMessageList: updateDeleteMessage,
          },
          true,
          "chatNessageList"
        );
      } else {
        return _dispatch(
          {
            ...state,
            chatMessageList: [...state.chatMessageList, action.payload.payload],
          },
          true,
          "chatNessageList"
        );
      }
      break;
    case PREPEND_CHAT_MESSAGE:
      return _dispatch(
        {
          ...state,
          chatMessageList: [
            ...action.payload.payload,
            ...state.chatMessageList,
          ],
        },
        true,
        "chatNessageList"
      );
      break;
    case GET_THREAD_LIST:
      return _dispatch(
        {
          ...state,
          threadList: action.payload,
        },
        true,
        "chatNessageList"
      );
    case UPDATE_CHAT_MESSAGE:
      let findMessageIndex = 0;
      findMessageIndex = _.findIndex(state.chatMessageList, [
        "id",
        action.payload.payload.id,
      ]);
      if (findMessageIndex > -1) {
        let updateMessage = [...state.chatMessageList];
        updateMessage[findMessageIndex] = action.payload.payload;
        return _dispatch(
          {
            ...state,
            chatMessageList: updateMessage,
          },
          true,
          "chatNessageList"
        );
      }
    case REPLY_CHAT_MESSAGE:
      return _dispatch(
        {
          ...state,
          chatMessageList: [...state.chatMessageList, action.payload.payload],
        },
        true,
        "chatNessageList"
      );
    case ONLINE_CHAT_USER:
      return _dispatch(
        {
          ...state,
          onlineUser:
            action.payload.status == "online" &&
            !_.find(state.onlineUser, ["userId", action.payload.userId])
              ? [...state.onlineUser, action.payload]
              : state.onlineUser.filter(
                  (online) => online.userId != action.payload.userId
                ),
        },
        true,
        "onlineuser"
      );
    case MEMBER_ALREADY_GROUP:
      return _dispatch(
        {
          ...state,
          groupmemberInfo: action.payload,
        },
        true,
        "onlineuser"
      );
    case UPDATE_MESSAGE_COUNT_STATUS:
      let readMessageIndex = 0;
      readMessageIndex = _.findIndex(state.threadList, [
        "thread_id",
        action.payload.thread_id,
      ]);
      if (readMessageIndex > -1) {
        let updateMessageThreadCount = [...state.threadList];
        updateMessageThreadCount[readMessageIndex].unread_count = 0;
        return _dispatch(
          {
            ...state,
            threadList: updateMessageThreadCount,
          },
          true,
          "messageCount"
        );
      }
    //   case DECREASE_MESSAGE_COUNT:
    //      let decreaseMessageCountIndex= _.findIndex(state.threadList, ["thread_id", action.payload.thread_id]);
    //       if(decreaseMessageCountIndex>-1){
    //           var updatedThreadedCount=[...state.threadList];
    //           updatedThreadedCount[decreaseMessageCountIndex].unread_count=updatedThreadedCount[decreaseMessageCountIndex].unread_count-1;
    //           return _dispatch({
    //             ...state,
    //             threadList: updatedThreadedCount
    //         }, true, 'decreaseCount');
    //       }
    case INCREASE_MESSAGE_COUNT:
      let increaseMessageCountIndex = _.findIndex(state.threadList, [
        "thread_id",
        action.payload.thread_id,
      ]);
      console.log("updatedThreadedCount", increaseMessageCountIndex);
      if (increaseMessageCountIndex > -1) {
        let updatedThreadedCount = [...state.threadList];
        updatedThreadedCount[increaseMessageCountIndex].unread_count++;
        return _dispatch(
          {
            ...state,
            threadList: updatedThreadedCount,
          },
          true,
          "increasaeCount"
        );
      } else {
        console.log("state", [...state.threadList]);
        return _dispatch(
          {
            ...state,
            threadList: [...state.threadList],
          },
          true,
          "increasaeCount"
        );
      }
    case GET_ALL_UNREAD_MESSAGE_COUNT:
      return _dispatch(
        {
          ...state,
          chatNotificationCount: action.payload,
        },
        true,
        "getAllunreadNotificationCount"
      );

    case GET_ALL_NOTIFICATION_CHAT:
      return _dispatch(
        {
          ...state,
          notificationChatList: [
            // ...state.notificationChatList,
            ...action.payload,
          ],
        },
        true,
        "getAllNotificatchatcount"
      );
    // case GET_ALL_NOTIFICATION:
    //   return _dispatch(
    //     {
    //       ...state,
    //       notificationList: [...state.notificationList, ...action.payload],
    //     },
    //     true,
    //     "getAllNotificatsLists"
    //   );
    case APPEND_NOTIFICATION_CHAT:
      //   return _dispatch(
      //     {
      //       ...state,
      //       notificationChatList: [action.payload, ...state.notificationChatList],
      //     },
      //     true,
      //     "append chat notification count"
      //   );
      const existingNotificationIndex = state.notificationChatList.findIndex(
        (notification) =>
          notification.ChatMessage.thread_id ===
          action.payload.ChatMessage.thread_id
      );

      if (existingNotificationIndex !== -1) {
        return _dispatch(
          {
            ...state,
            notificationChatList: [
              ...state.notificationChatList.slice(0, existingNotificationIndex),
              action.payload,
              ...state.notificationChatList.slice(
                existingNotificationIndex + 1
              ),
            ],
          },
          true,
          "append chat notification count"
        );
      } else {
        return _dispatch(
          {
            ...state,
            notificationChatList: [
              action.payload,
              ...state.notificationChatList,
            ],
          },
          true,
          "append chat notification count"
        );
      }
    case SORT_THREADLIST_CHAT:
      let findIndexOfthread = _.findIndex(state.threadList, [
        "thread_id",
        action.payload.thread_id,
      ]);
      if (findIndexOfthread > -1) {
        let updateThreaChatLastMessage = [...state.threadList];
        if (
          updateThreaChatLastMessage[findIndexOfthread]?.lastMessage?.id <=
            action.payload.payload.id ||
          updateThreaChatLastMessage[findIndexOfthread].lastMessage == null
        ) {
          updateThreaChatLastMessage[findIndexOfthread].lastMessage =
            action.payload.payload;
        }
        updateThreaChatLastMessage = _.sortBy(
          state.threadList,
          "lastMessage.id"
        );

        updateThreaChatLastMessage.reverse();
        let array1 = [];
        let array2 = [];
        updateThreaChatLastMessage.filter((item) => {
          if (item.lastMessage == null) {
            array1.push(item);
          } else {
            array2.push(item);
          }
        });
        updateThreaChatLastMessage = array2.concat(array1);
        return _dispatch(
          {
            ...state,
            threadList: updateThreaChatLastMessage,
          },
          true,
          "SORT_THREADLIST_CHAT"
        );
      } else {
        return _dispatch(
          {
            ...state,
            threadList: [...state.threadList],
          },
          true,
          "SORT_THREADLIST_CHAT"
        );
      }
    case FORWARD_MESSAGE_LIST:
      let data = [];
      let data1 = [];
      var prependThreadForwardMessage = action.payload.payload.filter(
        (forward) => {
          if (forward?.isNewThread == true) {
            data.push(forward.newThreadDetails);
          } else if (forward?.isNewThread == false) {
            data1.push(forward.message);
          }
        }
      );
      if (data.length > 0) {
        return _dispatch(
          {
            ...state,
            threadList: [...data, ...state.threadList],
          },
          true,
          "forwarMessageArray"
        );
      }
      if (data1.length > 0) {
        var updateforwardcharLastMessage = [...state.threadList];
        for (let a of data1) {
          let findIndexForwardMessage = _.findIndex(state.threadList, [
            "thread_id",
            a?.thread_id,
          ]);
          if (findIndexForwardMessage > -1) {
            if (
              updateforwardcharLastMessage[findIndexForwardMessage]?.lastMessage
                ?.id <= a?.id ||
              updateforwardcharLastMessage[findIndexForwardMessage]
                .lastMessage == null
            ) {
              updateforwardcharLastMessage[
                findIndexForwardMessage
              ].lastMessage = a;
            }
          }
        }
        updateforwardcharLastMessage = _.sortBy(
          state.threadList,
          "lastMessage.updatedAt"
        );
        updateforwardcharLastMessage.reverse();
        let array1 = [];
        let array2 = [];
        updateforwardcharLastMessage.filter((item) => {
          if (item.lastMessage == null) {
            array1.push(item);
          } else {
            array2.push(item);
          }
        });
        updateforwardcharLastMessage = array2.concat(array1);
        return _dispatch(
          {
            ...state,
            threadList: updateforwardcharLastMessage,
          },
          true,
          "forwarMessageArray"
        );
      }

    case CLEAR_CHAT_NOTFICATION:
      return _dispatch(
        {
          ...state,
          notificationChatList: action.payload,
        },
        true,
        "append chat notification count"
      );
    case "LOGOUT_USER_CLEAR":
      localStorage.clear();
      return {
        ...state,
        thread_id: {},
        buildingId: null,
        chatMessageList: [],
        onlineUser: [],
        threadList: [],
        chatNotificationCount: 0,
        notificationChatList: [],
      };
    default:
      return state;
  }
}
