import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import MemoryStorage from "redux-persist-memory-storage";
import promise from "redux-promise-middleware";
import logger from "redux-logger";
import ReduxThunk from "redux-thunk";
import axios from "axios";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers/index";
import { clearSession } from "./utilities";
import { toast } from "react-toastify";

const middleware = applyMiddleware(ReduxThunk, logger);
//const middleware = applyMiddleware(ReduxThunk);

const persistConfig = {
  key: "root",
  storage,
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer, compose(middleware));
export const persistor = persistStore(store);

//Send access token in every request
axios.interceptors.request.use(
  function (config) {
    const accessToken = window.localStorage.jwtToken || null;

    const UTCOffset = parseInt(
      new Date().getTimezoneOffset().toString()
    ).toString();
    const language = window.localStorage.contentlanguage || "en";
    config.headers = {
      authorization: `Bearer ${accessToken}`,
      utcoffset: UTCOffset,
      language: language,
    };

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const formatMessage = (text) => {
  let a = text.replace(/_/g, " ").toLowerCase();
  return a.charAt(0).toUpperCase() + a.slice(1);
};

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error?.response, "rrrrr ");
    if (
      typeof error?.response !== "undefined" &&
      typeof error?.response?.status !== "undefined"
    ) {
      switch (error.response.status) {
        // Unauthorized User - HTTP STATUS 401 - Error Handling
        case 401:
          error?.response?.data?.message
            ? toast.error(error?.response?.data?.message)
            : toast.error(
                "Sorry, something went wrong. Please try again later."
              );
          break;

        case 400:
          error?.response?.data?.message
            ? toast.error(error?.response?.data?.message)
            : toast.error(
                "Sorry, something went wrong. Please try again later."
              );
          break;
        case 403:
          error?.response?.data?.message
            ? toast.error(error?.response?.data?.message)
            : toast.error(
                "Sorry, something went wrong. Please try again later."
              );
          //clearSession();
          break;
        case 409:
          console.log("error.response.status", error.response);
          error?.response?.data?.message
            ? toast.error(error?.response?.data?.message)
            : toast.error(
                "Sorry, something went wrong. Please try again later."
              );
          break;
        // Server Side Issues Handling
        case 500:
          error?.response?.data?.message
            ? toast.error(error?.response?.data?.message)
            : toast.error(
                "Sorry, something went wrong. Please try again later."
              );
          break;
        default:
          toast.error("Sorry, something went wrong. Please try again later.");
      }
    }

    return Promise.reject(error);
  }
);
