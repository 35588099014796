import axios from 'axios';
import { data } from 'jquery';
import { API_ROOT } from '../constants';
const ROOT_URL = API_ROOT;

export function getUserDetails(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/user/getUserDetails`,
  });
}

