import {
    _dispatch,
    TENANTS_LIST_SUCCESS,
    BUILDING_ID
} from '../actions/tenant';



const INITIAL_STATE = { status: null, tenant_list: {}, buildingId: null };



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case TENANTS_LIST_SUCCESS:
            return _dispatch({ ...state, tenant_list: action.payload }, true, 'tenantChkList');
            break;
            case BUILDING_ID:
                return _dispatch({ ...state, buildingId: action.payload }, true, 'buildingId');
                break;
        default:
            return state;
    }
}