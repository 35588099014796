import React from 'react';

const NetworkError = () => {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1 style={{ color: 'red' }}>No Internet Connection</h1>
      <p>Please check your internet connection and try again.</p>
    </div>
  );
};

export default NetworkError;
