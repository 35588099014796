import _ from "lodash";
import {
  GET_ALL_NOTIFICATION,
  _dispatch,
  PREPEND_NOTIFICATION,
} from "../actions/notification";
const INITIAL_STATE = {
  notificationList: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALL_NOTIFICATION:
      return _dispatch(
        {
          ...state,
          notificationList: [...action.payload],
        },
        true,
        "getAllNotificatsLists"
      );

    case PREPEND_NOTIFICATION:
      return {
        ...state,
        notificationList: [action.payload, ...state.notificationList],
      };

    case "LOGOUT_USER_CLEAR":
      localStorage.clear();
      return {
        ...state,
        notificationList: [],
      };
    default:
      return state;
  }
}
