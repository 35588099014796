import axios from 'axios';
import { data } from 'jquery';
import { API_ROOT } from '../constants';
const ROOT_URL = API_ROOT;

export function manageBooking(data) {
  
  return axios({
    method: 'PATCH',
    data: data,
    url: `${ROOT_URL}/admin/updateBookingItem`,
  });
}

