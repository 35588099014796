import {
    _dispatch,
    TRANSACTION_LIST_SUCCESS
} from '../actions/getTransactions';



const INITIAL_STATE = { status: null, transaction_list: {} };



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case TRANSACTION_LIST_SUCCESS:
            return _dispatch({ ...state, transaction_list: action.payload }, true, 'transactionChkList');
            break;
         case 'LOGOUT_USER':
        default:
            return state;
    }
}