import axios from "axios";
import { data } from "jquery";
import { API_ROOT, _dispatch } from "../constants";
import { Redirect } from "react-router-dom";
import { socket } from "../components/Dashboard";
export { _dispatch };

const ROOT_URL = API_ROOT;

export const GET_ALL_NOTIFICATION = "GET_ALL_NOTIFICATION";
export const PREPEND_NOTIFICATION = "PREPEND_NOTIFICATION";

export function NotificationsList(payload) {
  return {
    type: GET_ALL_NOTIFICATION,
    payload: payload,
  };
}

export function prependNotification(payload) {
  return {
    type: PREPEND_NOTIFICATION,
    payload: payload,
  };
}
