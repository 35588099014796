import React, { Component } from 'react';

class ContentLoader extends Component {
  render() {
    return (
      <div id="page-loader">
          <div className="loader"></div>
      </div>
    );
  }
}

export default ContentLoader;
