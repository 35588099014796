import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { API_ROOT } from "../../constants";
import moment from "moment";
import $ from "jquery";
import { manageBooking } from "../../actions/manageBookingSpace";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

class ReadNotificationListSlide extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      slideData: props.slideData,
      declinedBookingReason: "Decline",
      approveStatus: "Approve",
    };
    this.getDirectPath = this.getDirectPath.bind(this);
    this.manageBookingStatus = this.manageBookingStatus.bind(this);
    this.checkFields = this.checkFields.bind(this);
    this.handleDecline = this.handleDecline.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.props.slideData) !==
      JSON.stringify(nextProps.slideData)
    ) {
      this.setState({
        slideData: nextProps.slideData,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.slideData !== this.props.slideData) {
      this.setState({
        slideData: this.props.slideData,
      });
    }
  }

  getDirectPath = (obj) => {
    const queryParams = {
      showModal: true,
    };
    const queryString = new URLSearchParams(queryParams).toString();

    let directPath = "";
    if (obj && obj.type === 1) {
      directPath = "/dashboard/manage-bookings";
    } else if (obj && obj.type === 2) {
      directPath = "/dashboard/tickets";
    } else if (obj && obj.type === 3) {
      directPath = "/dashboard/poll";
    } else if (obj && obj.type === 4) {
      directPath = `/dashboard/event/${obj.entity_id}?${queryString}`;
    } else if (obj && (obj.type === 5 || obj.type === 9 || obj.type === 10)) {
      directPath = `/dashboard/blog-news/details/${
        obj.otherDetails.slug ? obj.otherDetails.slug : "#"
      }`;
    }
    return directPath;
  };

  manageBookingStatus = async (booking, status) => {
    let dataToSend = {
      newStatus: status,
      bookingItemId: booking.entity_id,
    };
    if (status === 2 || status === 3) {
      dataToSend["declinedBookingReason"] = this.state.declinedBookingReason;
    }
    return manageBooking(dataToSend)
      .then((result) => {
        this.setState((prevState) => ({
          slideData: prevState.slideData.map((item) =>
            item.entity_id === booking.entity_id
              ? { ...item, approveStatus: status }
              : item
          ),
        }));

        toast.success("Success");
        $("#manage_booking_modal").hide();
        $("#cancel_booking_modal").hide();

        $(".modal-backdrop").hide();
        $(".modal-open").removeClass();
        this.props.onRedirection(booking);
      })
      .catch((error) => {});
  };

  checkFields = (bookingId, status) => {
    this.manageBookingStatus(bookingId, status);
  };

  handleDecline = (booking, status, event) => {
    event.stopPropagation();
    this.setState((prevState) => ({
      slideData: prevState.slideData.map((item) =>
        item.entity_id === booking.entity_id
          ? {
              ...item,
              otherDetails: {
                ...item.otherDetails,
                BookingItem: { ...item.otherDetails.BookingItem, status },
              },
            }
          : item
      ),
    }));
    this.manageBookingStatus(booking, status);
  };

  handleApprove = (booking, event) => {
    event.stopPropagation();
    this.setState((prevState) => ({
      slideData: prevState.slideData.map((item) =>
        item.entity_id === booking.entity_id
          ? {
              ...item,
              otherDetails: {
                ...item.otherDetails,
                BookingItem: { ...item.otherDetails.BookingItem, status: 1 },
              },
            }
          : item
      ),
    }));
    this.manageBookingStatus(booking, 1);
  };

  render() {
    return (
      <>
        <div className="notification-list-slide-container scroll-hide">
          {this.state.slideData &&
            this.state.slideData.map((obj, i) => (
              <div
                key={i}
                className={`noti-row ${obj.readStatus === 0 ? "unread" : ""}`}
                onClick={() => {
                  this.props.onRedirection(obj);
                }}
              >
                <figure className="profile-pic">
                  <img
                    src={
                      obj?.otherDetails?.User?.UserProfile?.Attachment?.path
                        ? `${API_ROOT}/${obj?.otherDetails?.User?.UserProfile?.Attachment?.path}`
                        : "/assets/images/no-image.png"
                    }
                    alt="Profile"
                  />
                </figure>
                <div className="chat-content-wrpr">
                  <NavLink to={this.getDirectPath(obj)}>
                    <p className="msg">{obj.description}</p>
                    <div className="d-flex justify-content-between">
                      <p className="time" id="timeNotify">
                        {moment(obj.createdAt).fromNow()}
                      </p>
                      <p className="chat-building"> {obj?.building_name}</p>
                    </div>
                  </NavLink>
                  <div className="noti-bottom-row">
                    {obj && obj.type === 1 ? (
                      <div className="noti-btn-wrpr">
                        {obj?.otherDetails?.BookingItem?.status === 0 ? (
                          <div
                            className="btn-blk"
                            style={{ display: "flex", gap: "20px" }}
                          >
                            <button
                              className="noti-secondary-btn"
                              onClick={(event) =>
                                this.handleDecline(obj, 2, event)
                              }
                            >
                              Decline
                            </button>
                            <button
                              className="noti-primary-btn"
                              onClick={(event) =>
                                this.handleApprove(obj, event)
                              }
                            >
                              Approve
                            </button>
                          </div>
                        ) : obj?.otherDetails?.BookingItem?.status === 1 ? (
                          <div className="btn-blk approved manage-booking ">
                            {moment(
                              obj?.otherDetails?.BookingItem?.bookedFrom
                            ) > moment() ? (
                              <>
                                <button
                                  className=" approved-btn"
                                  style={{
                                    marginRight: "15px",
                                    background: "#fff",
                                  }}
                                >
                                  Approved
                                </button>
                              </>
                            ) : (
                              <button className="decline-btn mr-3">
                                Booking Expired
                              </button>
                            )}
                          </div>
                        ) : (
                          <div className="btn-blk">
                            <button
                              className="decline-btn mr-3"
                              style={{ paddingLeft: "0px" }}
                            >
                              Cancelled
                            </button>
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}

          {this.props.loading && (
            <div className="tab-loading-content">
              <div className="loader"></div>
            </div>
          )}
        </div>
        <ToastContainer position="bottom-center" hideProgressBar={true} />
      </>
    );
  }
}

export default ReadNotificationListSlide;
