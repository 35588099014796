import {
    _dispatch,
    TICKET_LIST_SUCCESS,
    BUILDING_ID
} from '../actions/tickets';



const INITIAL_STATE = { status: null, ticket_list: {}, buildingId: null };



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case TICKET_LIST_SUCCESS:
            return _dispatch({ ...state, ticket_list: action.payload }, true, 'ticketChkList');
            break;
        case BUILDING_ID:
            return _dispatch({ ...state, buildingId: action.payload }, true, 'buildingId');
            break;

        default:
            return state;
    }
}