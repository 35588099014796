import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import Tickets from "./tickets";
import Summary from "./ticketSummary";
import BuildingUsers from "./usersList";
import Tenants from "./tenants";
import Chats from "./chat";
import Transaction from "./transactionList";
import Acl from "./acl";
import Notification from "./notification";
const appReducer = combineReducers({
  form: formReducer,
  Tickets,
  Summary,
  Tenants,
  BuildingUsers,
  Chats,
  Transaction,
  Acl,
  Notification,
});

const rootReducer = (state, action) => {
  if (action.type == "LOGOUT_USER") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
