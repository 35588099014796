import axios from 'axios';
import { data } from 'jquery';
import { API_ROOT ,_dispatch } from '../constants';
export {_dispatch};
export const USERS_LIST_SUCCESS = 'USERS_LIST_SUCCESS';
const ROOT_URL = API_ROOT;

export function getUsersList(data) {
  return axios({
    method: 'GET',
    params: data,
    url: `${ROOT_URL}/user/listUsers`,
  });
}

export function getUsersListSuccess(payload){
  return{
    type: "USERS_LIST_SUCCESS",
    payload: payload
  }
}


export function getUserSummary(data) {
    return axios({
      method: 'GET',
      params: data,
      url: `${ROOT_URL}/user/getUserSummary`,
    });
  
}
