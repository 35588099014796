import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { API_ROOT } from "../../constants";
import moment from "moment";
import ChatNotificationMessageReaction from "../chat/ChatNotifcationMessageReaction";
import _ from "lodash";

class ReadChatNotificationListSlide extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      slideData: props.slideData,
    };
    // this.getDirectPath = this.getDirectPath.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      slideData: nextProps.slideData,
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.slideData !== this.props.slideData) {
      this.setState({
        slideData: this.props.slideData,
      });
    }
  }

  getDirectPath = (obj) => {
    const queryParams = {
      showModal: true,
    };
    const queryString = new URLSearchParams(queryParams).toString();

    let directPath = "";
    if (obj && obj.type === 1) {
      directPath = "/dashboard/manage-bookings";
    } else if (obj && obj.type === 2) {
      directPath = "/dashboard/tickets";
    } else if (obj && obj.type === 3) {
      directPath = "/dashboard/poll";
    } else if (obj && obj.type === 4) {
      directPath = `/dashboard/event/${obj.entity_id}?${queryString}`;
    } else if (obj && (obj.type === 5 || obj.type === 9 || obj.type === 10)) {
      directPath = `/dashboard/blog-news/details/${
        obj.otherDetails.slug ? obj.otherDetails.slug : "#"
      }`;
    }
    return directPath;
  };

  render() {
    const { slideData, index } = this.state;

    const slidesToDisplay = slideData;

    // .slice(0, 4);

    return (
      <div className="notification-list-slide-container">
        {slidesToDisplay &&
          slidesToDisplay.map((chatMessage, index) => (
            <div
              key={index}
              className={`noti-row ${this.props.unread ? "unread" : ""}`}
              onClick={() => {
                this.props.redirectTochatThreadDetail(chatMessage);
              }}
            >
              <figure className="profile-pic">
                <img
                  src={`${API_ROOT}/${
                    chatMessage?.ChatMessage?.type == "message"
                      ? chatMessage?.ChatMessage?.User?.UserProfile?.Attachment
                          ?.path
                      : chatMessage?.ChatMessage?.type == "reaction"
                      ? _.find(chatMessage?.ChatMessage?.ChatMessageReactions, [
                          "User.id",
                          chatMessage?.sender_id,
                        ])?.User?.UserProfile?.Attachment?.path
                      : null
                  }`}
                  alt="img"
                />
              </figure>
              <div className="chat-content-wrpr">
                <p className="chat_name">
                  {chatMessage?.ChatMessage?.type == "message"
                    ? chatMessage?.ChatMessage?.User?.UserProfile?.name
                    : chatMessage?.ChatMessage?.type == "reaction"
                    ? _.find(chatMessage?.ChatMessage?.ChatMessageReactions, [
                        "User.id",
                        chatMessage?.sender_id,
                      ])?.User?.name
                    : null}
                </p>
                <p className="msg">
                  {" "}
                  {chatMessage?.ChatMessage && (
                    <>
                      {chatMessage?.ChatMessage?.message != "" &&
                      chatMessage?.ChatMessage?.ChatAttachments?.length > 0 ? (
                        this.props.stripHtmlTags(
                          chatMessage?.ChatMessage?.message,
                          15
                        )
                      ) : chatMessage?.ChatMessage?.message === "" &&
                        chatMessage?.ChatMessage?.ChatAttachments?.length >
                          0 ? (
                        "Sent Attachment"
                      ) : chatMessage?.ChatMessage?.message != "" &&
                        chatMessage?.ChatMessage?.ChatAttachments?.length ==
                          0 ? (
                        this.props.stripHtmlTags(
                          chatMessage?.ChatMessage?.message,
                          15
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </p>
                <div className="d-flex justify-content-between">
                  <p className="time">
                    {moment(chatMessage?.ChatMessage?.createdAt).fromNow()}
                  </p>

                  <p className="chat-building">{chatMessage?.building?.name}</p>
                </div>

                <ChatNotificationMessageReaction
                  message={chatMessage}
                  type="chatNotification"
                />
              </div>
            </div>
          ))}

        {/* {!this.props.showAll && slideData.length > 4 && (
          <button className="view-all-btn" onClick={this.props.onViewAll}>
            View All
          </button>
        )} */}
      </div>
    );
  }
}

export default ReadChatNotificationListSlide;
