import React, { Component } from "react";
import {
  getNotification,
  Marknotificationasread,
} from "../../actions/notifications";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./../chat/Chat.css";
import _ from "lodash";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import ReadNotificationListSlide from "./ReadNotificationListSlide";
import { NotificationsList } from "../../actions/notification";

class NotificationList extends Component {
  constructor(props, context) {
    super(props, context);
    this.notificatonref = React.createRef();
    this.state = {
      notificatioTotalPage: 0,
      unreadNotificationTotalPage: 0,
      notificationList: [],
      unreadNotificationList: [],
      notificationCount: 0,
      unreadNotificationCount: 0,
      activePage: 1,
      notificationActivePage: 1,
      showAllAllTab: false,
      showAllUnreadTab: false,
      loading: false,
    };
    this.notificationonScroll = this.notificationonScroll.bind(this);
    this.handleViewAllAllTab = this.handleViewAllAllTab.bind(this);
    this.handleViewAllUnreadTab = this.handleViewAllUnreadTab.bind(this);
    this.redirection = this.redirection.bind(this);
    this.marknotificationasread = this.marknotificationasread.bind(this);
  }
  handleViewAllAllTab = () => {
    this.setState({ showAllAllTab: true });
  };

  handleViewAllUnreadTab = () => {
    this.setState({ showAllUnreadTab: true });
  };
  componentDidMount() {
    this.getNotifications();
    // this.getUnreadNotification();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.notificationActivePage != this.state.notificationActivePage &&
      prevState.notificationActivePage < this.state.notificatioTotalPage
    ) {
      this.getNotifications();
    }
    if (
      prevState.notificationActivePage != this.state.notificationActivePage &&
      prevState.notificationActivePage < this.state.unreadNotificationTotalPage
    ) {
      // this.getUnreadNotification();
    }
  }
  getNotifications() {
    this.setState({ loading: true });
    return getNotification({
      pageNumber: this.state.notificationActivePage,
    })
      .then((result) => {
        const newData = result.data.responseData.data;

        console.log(`newDataaa`, newData);
        this.props.NotificationsList(newData);
        const unreadData = this.props.notificationList.filter(
          (i) => i.readStatus == 0
        );
        console.log(`1111`, unreadData);
        this.setState((prevState) => ({
          notificatioTotalPage: result.data.responseData.totalPages,
          notificationList: [
            ...prevState.notificationList,
            ...this.props.notificationList,
          ],
          notificationCount: result.data.responseData.dataCount,

          unreadNotificationTotalPage: result.data.responseData.totalPages,
          unreadNotificationList: [
            ...prevState.unreadNotificationList,
            ...unreadData,
          ],
          unreadNotificationCount: unreadData.length,
          loading: false,
        }));
      })
      .catch((error) => {});
  }
  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.props.notificationList) !=
      JSON.stringify(nextProps.notificationList)
    ) {
      console.log(`nextProps`, nextProps.notificationList);
      this.setState({
        notificationList: nextProps.notificationList,
        unreadNotificationList: [
          nextProps.notificationList[0],
          ...this.state.unreadNotificationList,
        ],
        unreadNotificationCount: this.state.unreadNotificationCount + 1,
      });
      // this.getNotifications();
    }
  }
  // getUnreadNotification() {
  //   this.setState({ loading: true });
  //   return getNotification({
  //     pageNumber: this.state.notificationActivePage,
  //   })
  //     .then((result) => {
  //       const newData = result.data.responseData.data;
  //       this.setState((prevState) => ({
  //         unreadNotificationTotalPage: result.data.responseData.totalPages,
  //         unreadNotificationList: [
  //           ...prevState.unreadNotificationList,
  //           ...newData,
  //         ],
  //         unreadNotificationCount: result.data.responseData.dataCount,
  //         loading: false,
  //       }));
  //     })

  //     .catch((error) => {});
  // }

  marknotificationasread(id) {
    Marknotificationasread({ notificationId: id })
      .then((res) => {
        const newUnreadNotifi = this.state.unreadNotificationList?.filter(
          (item) => item.id !== id
        );
        const newNotifi = this.state.notificationList?.map((item) => {
          if (item.id == id) {
            return { ...item, readStatus: 1 };
          } else {
            return item;
          }
        });
        this.setState({ unreadNotificationList: newUnreadNotifi });
        this.setState({ notificationList: newNotifi });
        if (this.state.unreadNotificationCount > 0) {
          this.setState({
            unreadNotificationCount: this.state.unreadNotificationCount - 1,
          });
        }
      })
      .catch((err) => console.log(err, "err"));
  }

  redirection(obj) {
    setTimeout(() => {
      this.marknotificationasread(obj.id);

      this.setState({ notificationModal: false });
    }, 500);

    return;
  }

  notificationonScroll(e) {
    if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) {
      this.setState({
        notificationActivePage: this.state.notificationActivePage + 1,
      });
    }
  }

  render() {
    const {
      notificationList,
      notificationCount,
      unreadNotificationCount,
      unreadNotificationList,
      showAllAllTab,
      showAllUnreadTab,
      loading,
    } = this.state;

    return (
      <li className="chat-noti-item mr-12">
        <div className="dropdown notification">
          <button
            className="notification bell-icon"
            onClick={() => {
              this.setState({ notificationModal: true });
            }}
          >
            {unreadNotificationCount === 0 ? (
              <></>
            ) : (
              <span className="badge">
                {unreadNotificationCount > 99 ? (
                  <>
                    {" "}
                    99 <sup>+</sup>
                  </>
                ) : (
                  unreadNotificationCount
                )}
              </span>
            )}
          </button>
          <div
            className={`${
              this.state.notificationModal ? "open" : ""
            } noti-container-wrpr ${loading ? "loading-content" : ""}`}
          >
            <div
              className="noti-overlay"
              onClick={() => {
                this.setState({ notificationModal: false });
                this.setState({ showAllAllTab: false });
                this.setState({ showAllUnreadTab: false });
              }}
            ></div>

            <div
              className="noti-container"
              ref={this.notificatonref}
              onScroll={this.notificationonScroll}
            >
              <div className="noti-header b-0">
                <h5>Notifications</h5>
              </div>

              <Tabs
                defaultActiveKey="all"
                id="uncontrolled-tab-example"
                className="noti-chat-tabs"
              >
                <Tab eventKey="all" title={`All`}>
                  <div className="noti-chat-tabs">
                    {/* {loading ? (
                      <div className="tab-loading-content ">
                        <div className="loader"></div>
                      </div>
                    ) : (
                      notificationList && (
                        <ReadNotificationListSlide
                          loading={loading}
                          slideData={notificationList}
                          showAll={showAllAllTab}
                          onViewAll={this.handleViewAllAllTab}
                          onRedirection={this.redirection}
                        />
                      )
                    )} */}
                    {notificationList && (
                      <ReadNotificationListSlide
                        loading={loading}
                        slideData={notificationList}
                        showAll={showAllAllTab}
                        onViewAll={this.handleViewAllAllTab}
                        onRedirection={this.redirection}
                        // reloadNotifications={this.getNotifications.bind(this)}
                      />
                    )}
                  </div>
                </Tab>
                <Tab
                  eventKey="Unread"
                  title={`Unread (${unreadNotificationCount})`}
                >
                  <div className="noti-chat-tabs">
                    {unreadNotificationList && (
                      <ReadNotificationListSlide
                        loading={loading}
                        slideData={unreadNotificationList}
                        // slideData = {this.props.notificationList}
                        showAll={showAllUnreadTab}
                        onViewAll={this.handleViewAllUnreadTab}
                        onRedirection={this.redirection}
                        reloadNotifications={this.getNotifications.bind(this)}
                      />
                    )}
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </li>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    NotificationsList: (payload) => {
      dispatch(NotificationsList(payload));
    },
  };
};
const mapStatesToProps = (state, ownProps) => {
  return {
    notificationList: state.Notification.notificationList,
  };
};
export default withRouter(
  connect(mapStatesToProps, mapDispatchToProps)(NotificationList)
);
