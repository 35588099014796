import _ from "lodash";
import React from "react";

const ChatNotificationMessageReaction = ({ message, type }) => {
  console.log(`mmm`, message);
  const reactions = _.find(message?.ChatMessage?.ChatMessageReactions, [
    "User.id",
    message?.sender_id,
  ]);
  return message?.ChatMessage?.ChatMessageReactions?.length > 0 ? (
    <div className="chat-reactions">
      {reactions == undefined || reactions == null ? (
        <></>
      ) : (
        <span className="chat_noti_reaction">
          <img src={reactions?.reaction} alt="" />{" "}
        </span>
      )}
    </div>
  ) : null;
};

export default ChatNotificationMessageReaction;
