import axios from 'axios';
import { data } from 'jquery';
import { API_ROOT, _dispatch } from '../constants';
export { _dispatch };
export const USERS_LIST_SUCCESS = 'USERS_LIST_SUCCESS';
const ROOT_URL = API_ROOT;

export function getBuildings(params) {

  if (params && params.name == '') {
    delete params.name;
  }
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/listBuildings`,
  });
}
export function getBuildingsDetail(data) {
  return axios({
    method: 'GET',
    params: data,
    url: `${ROOT_URL}/admin/getBuildingDetails`,
  });
}