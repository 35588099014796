import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
  getNotification,
  Marknotificationasread,
  MarkThreadRead,
} from "../../actions/notifications";
import {
  getAllUnThreadCount,
  getAllUnreadMessageCount,
  getAllchatNotifications,
  NotificationsChatList,
  markAllAsReadchatNotification,
  clearChatNotificcation,
  sortThreadList,
  threadList,
  getThreadList,
  resetcountchatmemberChat,
} from "../../actions/chat";
import { API_ROOT } from "../../constants";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";
import "./../chat/Chat.css";
import _ from "lodash";
import ChatNotificationMessageReaction from "../chat/ChatNotifcationMessageReaction";
// import { getUserDetails } from '../actions/getUserDetail';
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import ReadChatNotificationListSlide from "./ReadChatNotificationListSlide";
// import ReadNotificationListSlide from "./ReadNotificationListSlide";

class ChatNotificationList extends Component {
  constructor(props, context) {
    super(props, context);
    this.notificatonref = React.createRef();
    this.state = {
      notificationList: [],
      unreadChatNotificationList: [],
      notificationCount: 0,
      activePage: 1,
      notificationActivePage: 1,
      allchatNotification: [],
    };
    // this.notificationonScroll = this.notificationonScroll.bind(this);
    // this.markAllAsReadchatNotifications = this.markAllAsReadchatNotifications.bind(this)
  }
  componentDidMount() {
    this.getChatNotification();
    this.getUnreadChatNotification();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.activePage != this.state.activePage) {
      this.getChatNotification();
    }
  }

  getChatNotification() {
    getAllUnThreadCount()
      .then((res) => {
        if (res?.data?.responseData != null) {
          this.props.getAllUnreadMessageCount(res?.data?.responseData);
        }
      })
      .catch((err) => {});
    getAllchatNotifications({
      pageNumber: this.state.activePage,
      readFlag: true,
    })
      .then((res) => {
        console.log(`hhhhhhhhhh`, res);
        // this.setState({ allchatNotification: res?.data?.responseData?.data });
        if (
          this.state.totalPages == 1 &&
          res?.data?.responseData?.data.length == 0
        ) {
          this.props.clearChatNotificcation(res?.data?.responseData?.data);
        } else {
          this.props.NotificationsChatList(res?.data?.responseData?.data);
        }
      })
      .catch((err) => {});
  }

  getUnreadChatNotification() {
    return getAllchatNotifications({
      pageNumber: this.state.activePage,
      readFlag: false,
    })
      .then((result) => {
        this.setState({
          unreadChatNotificationList: result.data.responseData?.data,
        });
      })
      .catch((error) => {});
  }

  stripHtmlTags(str, length) {
    if (str === null || str === "") {
      return false;
    } else {
      str = str?.toString();
      str = str?.replace(/<[^>]*>/g, "");
      if (length && length > 0 && str?.length > length) {
        str = str?.substr(0, length);
        str = str + "...";
      }
    }
    return str;
  }

  redirectTochatThreadDetail(obj) {
    MarkThreadRead({
      buildingId: obj?.building_id,
      threadId: obj?.ChatMessage?.thread_id,
    });
    var encodeUrl = encodeURIComponent(
      `thread_id=${obj?.ChatMessage?.thread_id}`
    );
    const loadershow = false;
    this.props.history.push({
      pathname: `/dashboard/chat`,
      search: `?buildingId=${
        obj?.building_id
      }&${encodeUrl}&show=${true}&time=${Date.now()}`,
    });
    let findIndexOfthread = _.findIndex(this.props.threadList, [
      "thread_id",
      obj?.ChatMessage?.thread_id,
    ]);
    if (findIndexOfthread > -1) {
      this.props.sortThreadList({
        thread_id: obj?.ChatMessage?.thread_id,
        payload: obj?.ChatMessage,
      });
    }
    if (obj?.building_id) {
      getThreadList({ building_id: obj?.building_id })
        .then((response) => {
          this.props.threadList(response?.data?.responseData?.rows);
          if (obj?.ChatMessage?.thread_id) {
            resetcountchatmemberChat({ thread_id: obj?.ChatMessage?.thread_id })
              .then((res) => {})
              .catch((err) => {
                console.log("err", err);
              });
          }
        })
        .catch((err) => {});
    }
  }

  render() {
    const {
      notificationList,
      notificationCount,
      unreadChatNotificationList,
      allchatNotification,
    } = this.state;
    const sortChatMessages=this.props.notificationChatList?.sort((a, b) => new Date(b?.ChatMessage?.updatedAt) - new Date(a?.ChatMessage?.updatedAt));

    console.log("allchatNotification", this.props.notificationChatList);
    return (
      <li className="chat-noti-item">
        <div className="dropdown notification">
          <button
            className="notification chat-icon"
            onClick={() => {
              this.setState({ chatModal: true });
            }}
          >
            {/* <span className="chat-icon"> */}
            {this.props.chatNotificationCount === 0 ? (
              <></>
            ) : (
              <span className="badge">
                {this.props.chatNotificationCount > 99 ? (
                  <>
                    {" "}
                    99 <sup>+</sup>
                  </>
                ) : (
                  this.props.chatNotificationCount
                )}
              </span>
            )}
            {/* </span> */}
          </button>
          <div
            className={`${
              this.state.chatModal ? "open" : ""
            } noti-container-wrpr`}
          >
            <div
              className="noti-overlay"
              onClick={() => {
                this.setState({ chatModal: false });
              }}
            ></div>

            <div className="noti-container">
              <div className="noti-header b-0">
                <h5>Chat</h5>
              </div>
              <Tabs
                defaultActiveKey="all"
                id="uncontrolled-tab-example"
                className="noti-chat-tabs"
              >
                <Tab eventKey="all" title="All">
                  <div className="noti-chat-tabs">
                    {/* <ReadChatNotificationListSlide
                      slideData={allchatNotification}
                      redirectTochatThreadDetail={
                        this.redirectTochatThreadDetail
                      }
                      stripHtmlTags={this.stripHtmlTags}
                    /> */}

                    {sortChatMessages &&
                      sortChatMessages?.map(
                        (chatMessage, index) => (
                          <div
                            className="chat-noti"
                            key={index}
                            onClick={() =>
                              this.redirectTochatThreadDetail(chatMessage)
                            }
                          >
                            <figure className="profile-pic">
                              <img
                                src={`${API_ROOT}/${
                                  chatMessage?.ChatMessage?.type == "message"
                                    ? chatMessage?.ChatMessage?.User
                                        ?.UserProfile?.Attachment?.path
                                    : chatMessage?.ChatMessage?.type ==
                                      "reaction"
                                    ? _.find(
                                        chatMessage?.ChatMessage
                                          ?.ChatMessageReactions,
                                        ["User.id", chatMessage?.sender_id]
                                      )?.User?.UserProfile?.Attachment?.path
                                    : null
                                }`}
                                alt="img"
                              />
                            </figure>
                            <div className="chat-content-wrpr">
                              <p className="chat_name">
                                {chatMessage?.ChatMessage?.type == "message"
                                  ? chatMessage?.ChatMessage?.User?.UserProfile
                                      ?.name
                                  : chatMessage?.ChatMessage?.type == "reaction"
                                  ? _.find(
                                      chatMessage?.ChatMessage
                                        ?.ChatMessageReactions,
                                      ["User.id", chatMessage?.sender_id]
                                    )?.User?.name
                                  : null}

                                  {
                                    chatMessage?.ChatMessage?.ChatThread?.type == "group"
                                    ?
                                    " ("+chatMessage?.ChatMessage?.ChatThread?.name+")"
                                    :
                                    null
                                  }


                              </p>
                              <p className="msg">
                                {" "}
                                {chatMessage?.ChatMessage && (
                                  <>
                                    {chatMessage?.ChatMessage?.message != "" &&
                                    chatMessage?.ChatMessage?.ChatAttachments
                                      ?.length > 0 ? (
                                      this.stripHtmlTags(
                                        chatMessage?.ChatMessage?.message,
                                        15
                                      )
                                    ) : chatMessage?.ChatMessage?.message ===
                                        "" &&
                                      chatMessage?.ChatMessage?.ChatAttachments
                                        ?.length > 0 ? (
                                      "Sent Attachment"
                                    ) : chatMessage?.ChatMessage?.message !=
                                        "" &&
                                      chatMessage?.ChatMessage?.ChatAttachments
                                        ?.length == 0 ? (
                                      this.stripHtmlTags(
                                        chatMessage?.ChatMessage?.message,
                                        15
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </p>
                              <div className="d-flex justify-content-between">
                                <p className="time">
                                  {moment(
                                    chatMessage?.ChatMessage?.createdAt
                                  ).fromNow()}
                                </p>

                                <p className="chat-building">
                                  {chatMessage?.building?.name}
                                </p>
                              </div>

                              <ChatNotificationMessageReaction
                                message={chatMessage}
                                type="chatNotification"
                              />
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </Tab>
                <Tab eventKey="unread" title="Unread">
                  <div className="noti-chat-tabs">
                    {/* <ReadChatNotificationListSlide
                      slideData={unreadChatNotificationList}
                      redirectTochatThreadDetail={
                        this.redirectTochatThreadDetail
                      }
                      stripHtmlTags={this.stripHtmlTags}
                      unread={true}
                    /> */}

                    {unreadChatNotificationList &&
                      unreadChatNotificationList?.map((chatMessage, index) => (
                        <div
                          className="chat-noti"
                          key={index}
                          onClick={() =>
                            this.redirectTochatThreadDetail(chatMessage)
                          }
                        >
                          <figure className="profile-pic">
                            <img
                              src={`${API_ROOT}/${
                                chatMessage?.ChatMessage?.type == "message"
                                  ? chatMessage?.ChatMessage?.User?.UserProfile
                                      ?.Attachment?.path
                                  : chatMessage?.ChatMessage?.type == "reaction"
                                  ? _.find(
                                      chatMessage?.ChatMessage
                                        ?.ChatMessageReactions,
                                      ["User.id", chatMessage?.sender_id]
                                    )?.User?.UserProfile?.Attachment?.path
                                  : null
                              }`}
                              alt="img"
                            />
                          </figure>
                          <div className="chat-content-wrpr">
                            <p className="chat_name">
                              {chatMessage?.ChatMessage?.type == "message"
                                ? chatMessage?.ChatMessage?.User?.UserProfile
                                    ?.name
                                : chatMessage?.ChatMessage?.type == "reaction"
                                ? _.find(
                                    chatMessage?.ChatMessage
                                      ?.ChatMessageReactions,
                                    ["User.id", chatMessage?.sender_id]
                                  )?.User?.name
                                : null}
                            </p>

                            <p className="msg">
                              {" "}
                              {chatMessage?.ChatMessage && (
                                <>
                                  {chatMessage?.ChatMessage?.message != "" &&
                                  chatMessage?.ChatMessage?.ChatAttachments
                                    ?.length > 0 ? (
                                    this.stripHtmlTags(
                                      chatMessage?.ChatMessage?.message,
                                      15
                                    )
                                  ) : chatMessage?.ChatMessage?.message ===
                                      "" &&
                                    chatMessage?.ChatMessage?.ChatAttachments
                                      ?.length > 0 ? (
                                    "Sent Attachment"
                                  ) : chatMessage?.ChatMessage?.message != "" &&
                                    chatMessage?.ChatMessage?.ChatAttachments
                                      ?.length == 0 ? (
                                    this.stripHtmlTags(
                                      chatMessage?.ChatMessage?.message,
                                      15
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </p>
                            <div className="d-flex justify-content-between">
                              <p className="time">
                                {moment(
                                  chatMessage?.ChatMessage?.createdAt
                                ).fromNow()}
                              </p>

                              <p className="chat-building">
                                {chatMessage?.building?.name}
                              </p>
                            </div>
                            <ChatNotificationMessageReaction
                              message={chatMessage}
                              type="chatNotification"
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUnreadMessageCount: (payload) => {
      dispatch(getAllUnreadMessageCount(payload));
    },
    NotificationsChatList: (payload) => {
      dispatch(NotificationsChatList(payload));
    },
    clearChatNotificcation: (payload) => {
      dispatch(clearChatNotificcation(payload));
    },
    sortThreadList: (thread_id, payload) => {
      dispatch(sortThreadList(thread_id, payload));
    },
    threadList: (payload) => {
      dispatch(threadList(payload));
    },
  };
};
const mapStatesToProps = (state, ownProps) => {
  return {
    chatNotificationCount: state.Chats.chatNotificationCount,
    notificationChatList: state.Chats.notificationChatList,
    threadList: state.Chats.threadList,
  };
};
export default withRouter(
  connect(mapStatesToProps, mapDispatchToProps)(ChatNotificationList)
);
