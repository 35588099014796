import axios from 'axios';
import { data } from 'jquery';
import { API_ROOT, _dispatch } from '../constants';
export { _dispatch };
export const TENANTS_LIST_SUCCESS = 'TENANTS_LIST_SUCCESS';
export const BUILDING_ID = 'TENANTS_LIST_SUCCESS';
const ROOT_URL = API_ROOT;

export function getTenantsList(data) {
    return axios({
        method: 'GET',
        params: data,
        url: `${ROOT_URL}/admin/listCompanies`,
    });
}

export function getTenantsListSuccess(payload) {
    return {
        type: "TENANTS_LIST_SUCCESS",
        payload: payload
    }
}

export function editTenant(data) {
    return axios({
        method: 'PATCH',
        data: data,
        url: `${ROOT_URL}/admin/updateCompany`,
    });
}


export function deleteTenant(data) {
    return axios({
        method: 'DELETE',
        data: data,
        url: `${ROOT_URL}/admin/deleteCompany`,
    });
}

export function addTenant(data) {
    return axios({
        method: 'POST',
        data: data,
        url: `${ROOT_URL}/admin/createCompany`,
    });
}